import React, { memo } from "react"
import Seo from "../components/Seo"

const NotFoundPage = memo(() => (
  <main>
    <h1 style={{ textAlign: "center", padding: "48px 24px 0" }}>
      404: Page Not Found
    </h1>
    <p style={{ textAlign: "center" }}>
      You just hit a page that doesn't exist...
    </p>
  </main>
))

export const Head = () => {
  return <Seo title="Page Not Found" />
}

export default NotFoundPage

import React, { Fragment, memo } from "react"
import { useStaticQuery, graphql } from "gatsby"

const Seo = memo(({ description, title, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            keywords
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const defaultTitle = site.siteMetadata.title
  const keywords =
    site.siteMetadata.keywords ??
    "son truong, sontruong, son truong dev, demo app, gatsby, gatsbyjs, threejs"
  const defaultDescription =
    description ??
    site.siteMetadata.description ??
    "A demo project to evaluate the performance of Three.js on Gatsby.js."
  const author = site.siteMetadata.author ?? "Son Truong"
  const siteUrl = site.siteMetadata.siteUrl ?? "https://demo.sontruong.dev"

  return (
    <Fragment>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={defaultDescription} />
      <meta property="og:title" content={`${title} | ${defaultTitle}`} />
      <meta property="og:description" content={defaultDescription} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content={author} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:site_name" content={`${title} | ${defaultTitle}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author || ``} />
      <meta name="twitter:title" content={`${title} | ${defaultTitle}`} />
      <meta name="twitter:description" content={defaultDescription} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Person",
          name: author,
          url: siteUrl,
          description: defaultDescription,
        })}
      </script>
      {children}
    </Fragment>
  )
})

export default Seo
